import React from "react";
import corestratLogo from "../../../Assets/corelogo.png";
import "./customLoader.css";
const CustomLoader = () => {
  return (
    <div className="loadingbackground">
      <div className="loader">
        {/* <img src={corestratLogo} alt="Corestrat Logo" /> */}
      </div>
      <div>Loading...
      {/* <div class="dot-flashing"></div> */}
      </div>
    </div>
  );
};

export default CustomLoader;
