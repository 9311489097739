import React, { useState, useEffect } from "react";
import { Typography, Box, LinearProgress, FormControl, Select, Alert, Grid, MenuItem, Button, Breadcrumbs, ThemeProvider } from "@mui/material";
import Header from "../Common/Headers/header";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import Breadcrumb from "../Common/Breadcrumbs/breadcrumbs";
import { useSelector, useDispatch } from "react-redux";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { useNavigate } from "react-router-dom";
import ReusableSnackbar from "../Common/Snackbar/snackbar";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { blueButtonStyle, greyButtonStyle, theme } from "../../constant2";
import CircularProgress from '@mui/material/CircularProgress';
// import Select from "@mui/material/Select";
// import Alert from "@mui/material/Alert";
import "./approvals.css";
import {
  getBankDetails,
  disburseLoan,
  verifyAccountPennyDrop,
  checkBalance,
} from "../Common/Apis/api";
import logout from "../Common/Logout/logout";
import { levelsofAccess } from "../Common/AccessLevels/accessLevel";
import CustomLoader from "../Common/CustomLoader/corestratLoader";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const DisbursementDetails1 = () => {
  const [user, setUser] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [accountVerified, setaccountVerified] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [getBalanceDetails, setgetBalanceDetails] = useState(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [modeoftransfer, setModeofTransfer] = React.useState("NEFT");
  const [editAccess, seteditAccess] = useState(false);
  const [showData, setShowData] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [level, setLevel] = useState("");
  const [loading, setLoading] = useState(false);
  const [successPennyDrop, setSuccessPennyDrop] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [pennyDropSuccess, setPennyDropSuccess] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showErrorText, setShowErrorText] = useState(false);
  const [showModeError, setShowModeError] = useState(false);
  const [modeErrorText, setModeErrorText] = useState("");
  const [loadingDisbursement, setLoadingDisbursement] = useState(false);

  const getCurrentTime = () => {
    const date = new Date();
    return date.toISOString();
  };
  const key = sessionStorage.getItem("key")?.toLowerCase(); 
  const currentTime = getCurrentTime();
  const confirmDisbursement = async() => {                                                       //getting partner_name from session storage
    setLoadingDisbursement(true);
    if (key) {
    try{
      const response = await dispatch(disburseLoan(bankDetails?.id, modeoftransfer, key, handleLogoutButton));        //allow disbursement of loans
      console.log(response);
      if (response?.status == 200) {
        navigate("./../DetailView");
        setLoadingDisbursement(false);
      }
      else{
        setLoadingDisbursement(false);
        setShowErrorAlert(true);
        setShowErrorText("Disbursement was unsuccessful. Try Again Later.")
        console.log("error from api",response?.status);
      }
    }
    catch(error){
      console.log(error);
    }
    }
  };
  const formatKey = (key) => {
    return key
      .replace(/_/g, " ")
      .replace(/^\w/, (c) => c.toUpperCase())
      .replace(/\b\w/g, (c) => c.toUpperCase());
  };

  const bankDetails = useSelector(
    (state) => state.DetailsReducers.bankDetailsResponse
  );
  const bankDetailsLoading = useSelector(
    (state) => state.DetailsReducers.bankDetailsLoading
  );
  useEffect(() => {
    try {
      const loanScheduleID = sessionStorage.getItem("FinappLoanCode");
      const key = sessionStorage.getItem("key");
      dispatch(getBankDetails(loanScheduleID, key, handleLogoutButton));
    } catch (e) { }
  }, []);

  useEffect(() => { }, [bankDetails]);

  useEffect(() => {
    const accessLevel = levelsofAccess("Disbursement");
    if (accessLevel?.toLowerCase() == "edit") {
      seteditAccess(true);
    }
    if (accessLevel?.toLowerCase() == "view") {
      seteditAccess(false);
    }
    if (accessLevel == null || accessLevel == undefined) {
      setAlertMessage( "You do not have permissions to access this page. Please contact your Site Adminstrator(s) to request access.");
      setShowData(false);
    }
  }, []);

  const handleLogoutButton = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    // Set a timer to navigate to the login page after at least 3 seconds
    setTimeout(() => {
      logout(navigate, dispatch); // Replace "/login" with your actual login route
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };
  const handleChange = (event) => {
    setModeofTransfer(event.target.value);
    if(bankDetails?.net_disbursement > 200000.00 && event.target.value == "NEFT"){
      setShowModeError(true);
      setModeErrorText("*The chosen Mode of Transfer is not valid for this disbursement. Please try a different mode of transfer.");
    }
    else{
      setShowModeError(false);
    }
  };

  useEffect(() => {
    if (bankDetails?.net_disbursement < 200000.00) {
      setModeofTransfer("NEFT");
    } 
    else {
      setModeofTransfer("RTGS"); 
    }
  }, [bankDetails?.net_disbursement]);


  const handleVerifyAccountClick = async () => {
    setShowErrorAlert(false);
    setLoading(true);
    try {
      const result = await verifyAccountPennyDrop(
        bankDetails?.id,
        key,
        handleLogoutButton
      );
      if (result?.data?.response_message == "SUCCESS") {
        setLoading(false);
        setaccountVerified(true);
        setPennyDropSuccess(true);
        const loanScheduleID = sessionStorage.getItem("FinappLoanCode");
        dispatch(getBankDetails(loanScheduleID, key, handleLogoutButton));
        setSuccessPennyDrop(true);
      }
      else{
        setLoading(false);
        setaccountVerified(true);
        setPennyDropSuccess(false);
        setErrorMessage("Penny Drop Unsuccessful");
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(error?.response?.data?.detail);
     }
  };

  useEffect(() => {
    balanceCheck();
  }, []);

  const balanceCheck = async () => {
    try {
      const result = await checkBalance(key,handleLogoutButton);
      if (result?.data?.response_message == "SUCCESS") {
        setgetBalanceDetails(result.data.response_data);
      }
    } catch (error) { }
  };
  return (
    <div>
    <ThemeProvider theme={theme}>
      <Header />
      {bankDetailsLoading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
          <CustomLoader/>
        </Box>
      ) : null}
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" sx={{color:"#ffffff"}}/>} className="breadcrumbsStyle" sx={{mt:1}}>
      <Breadcrumb
          icon={<AssignmentIndIcon />}
          name="Dashboard"
          path="/Dashboard"
        />
        <Breadcrumb
          icon={<CardTravelIcon />}
          name="Approvals and Disbursements"
          path="/Approvals"
        />
        <Breadcrumb icon={<FileCopyIcon />} name="Details" path="/DetailView" />
        <Breadcrumb
          icon={<ScheduleIcon />}
          name="Confirm Disbursement"
          path="/ConfirmDisbursement"
          isCurrentPage
        />
        {/*  <Breadcrumb
                    icon={<AccountCircleIcon />}
                    name="Customer Details"
                    path="/CustomerDetails"
                    isCurrentPage
                />*/}
      </Breadcrumbs>
      {showData ? (<>
      {bankDetails ? (
        <Grid
          container
          className="main-grid"
          sx={{ pt: 1, pl: 6, height: "100%" }}
        >
          <Grid item xs={12} sx={{mr:5, mt:2}}>
          {showErrorAlert ? (
            <Alert severity="error">
              {showErrorText}
            </Alert>
          ):null}
          </Grid>
          <Grid item sx={12} xs={12} lg={12}>
            <Typography
              variant="h6"
              gutterBottom
              className="blue-text"
              style={{ fontWeight: 300, fontSize: "24px" }}
              sx={{ pt: 2, pb: 0 }}
            >
              Account Details
            </Typography>
          </Grid>
          <Grid
            key={user.id}
            item
            xs={12}
            md={6}
            lg={6}
            sx={{ pb: 1, flexGrow: 1 }}
            className="grey-background"
          //add or change padding of the box
          >
            <div
              className="left-box"
              style={{ height: "100%", display: "flex" }}
            >
              <Grid item xs={12} sx={{ pt: 1, pl: 3 }}>
                {" "}
                {/*padding of typographic elements*/}
                <Grid
                  container
                  justifyContent="flex-end"
                  style={{ height: "100%" }}
                  spacing={2}
                  sx={{ pl: 2, pr: 2 }}
                >
                  {Object.keys(bankDetails)
                    .filter(
                      (key) =>
                        key !== "permanent_addr" &&
                        key !== "permanent_pincode" &&
                        key !== "present_addr" &&
                        key !== "present_pincode" &&
                        key !== "id"
                    )
                    .map((key) => (
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          sx={{ mr: 4, flex: 1 }}
                          component="div"
                          style={{ fontSize: "1rem" }}
                        >
                          <Typography
                            whiteSpace="nowrap"
                            sx={{ minWidth: "300px", mr: "10px" }}
                            component="div"
                            style={{
                              fontSize: "1rem",
                              fontWeight: "700",
                              display: "inline-block",
                            }}
                          >
                            {formatKey(key)}
                          </Typography>
                          : {bankDetails[key]}
                        </Typography>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            className="details-form"
            sx={{ minWidth: 100, pl: 5, pt:2 }}
          >
            {successPennyDrop ? (<>
              <Typography
                component="div"
                style={{
                  color: "#008000",
                  fontSize: "1rem",
                  fontWeight: "600",
                  display: "inline-block",
                }}
                sx={{ pt: 3 }}
              >
                Account Verified- Penny Drop Successful.
              </Typography>
              </>):(<>
            {!accountVerified ? (
              <>
              {loading ? (<>
               <Typography style={{ fontStyle: "italic" }}>
                 <CircularProgress size={"1rem"} style={{ color: "var(--primary-color)" }} sx={{ mr: 1 }}/>Checking<div class="dot-flashing"></div>
               </Typography>
               </>):(<>
                <Typography
                  component="div"
                  style={{
                    color: "blue",
                    fontSize: "1rem",
                    fontWeight: "100",
                    display: "inline-block",
                  }}
                  sx={{ pt: 3 }}
                >
                  <a className="blue-text" onClick={handleVerifyAccountClick}>
                    Verify the account
                  </a>
                </Typography>
                <Typography style={{ fontSize: "15px", fontStyle: "italic" }}>                  
                  Using penny drop
                </Typography>
                </>)}
              </>
            ) : (<>
            {pennyDropSuccess ? (<>
              <Typography
                component="div"
                style={{
                  color: "#008000",
                  fontSize: "1rem",
                  fontWeight: "600",
                  display: "inline-block",
                }}
                sx={{ pt: 3 }}
              >
                Account Verified- Penny Drop Successful.
              </Typography>
              </>):(<>
                <Typography
                component="div"
                style={{
                  color: "var(--fail-color)",
                  fontSize: "1rem",
                  fontWeight: "600",
                  display: "inline-block",
                }}
                sx={{ pt: 3 }}
              >
                {errorMessage}
              </Typography></>)}
              </>)}
              </>
            )}
            <Box sx={{display: "flex", alignItems: "center", minWidth: 100, pr: 15, pt: 5 }}>
              <Typography
                component="div"
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                }}
              >
                Mode of Transfer:&nbsp;
              </Typography>
              <FormControl sx={{ minWidth: 200}}>
                <Select
                  value={modeoftransfer}
                  onChange={handleChange}
                  // disabled={true}
                  width="25px"
                  sx={{
                    height: 40,
                    fontSize: "1em",
                  }}
                >
                  <MenuItem value="IMPS">IMPS</MenuItem>
                  <MenuItem value="NEFT">NEFT</MenuItem>
                  <MenuItem value="RTGS">RTGS</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {showModeError && <Typography sx={{m:2}} style={{color:"var(--fail-color)", fontSize:"0.7rem"}}>{modeErrorText}</Typography>}
            <Grid sx={{mt:5}}>
            {getBalanceDetails ? (
              <>
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "100",
                    display: "inline-block",
                    cursor: "pointer",
                  }}
                >
                  Balance:
                  {parseFloat(bankDetails?.loan_amount) >
                    parseFloat(getBalanceDetails?.AvailableBalance) ? (
                    <Typography
                      style={{
                        display: "inline",
                        color: "red",
                        verticalAlign: "middle",
                      }}
                    >
                      {" "}
                      NOT AVAILABLE
                      <CloseIcon style={{ verticalAlign: "top" }} />
                      
                    </Typography>
                  ) : (
                    <Typography
                      style={{
                        display: "inline",
                        color: "green",
                        verticalAlign: "middle",
                      }}
                    >
                      {" "}
                      AVAILABLE
                      <CheckIcon style={{ verticalAlign: "top" }} />
                      
                    </Typography>
                  )}
                </Typography>
              </>
            ) :  
            (<Typography style={{color:"var(--primary-color)", display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              fontSize:"1rem"}}>
                <CircularProgress size={"1rem"}/>&nbsp;Checking Balance...
            </Typography>
            )}
            </Grid>
            <Grid container justifyContent="flex-end" sx={{ mr: 2, mb: 2}}>
              <Button
                onClick={(event) => confirmDisbursement(event)}
                disabled={!editAccess || showModeError || loadingDisbursement}
                sx={{
                  mb: 2,
                  mr: 10,
                  mt: 5,
                  ...(editAccess && !showModeError && !loadingDisbursement ? blueButtonStyle : greyButtonStyle)
                }}
                startIcon={loadingDisbursement ? <CircularProgress size={20} color="inherit" /> : null}
              >Confirm Disbursement
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Alert severity="warning">
          The disbursement is not yet generated for this loan.{" "}
        </Alert>
      )}
      </>):(<><Grid item xs={12} md={12}>
              <Alert severity="error" sx={{m:5}}>{alertMessage}</Alert>
            </Grid></>)}
      <ReusableSnackbar
        open={showSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      </ThemeProvider>
    </div>
  );
};
export default DisbursementDetails1;
