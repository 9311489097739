import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  Alert,
  FormControl,
  Breadcrumbs,
  LinearProgress,
  Box,
  Autocomplete,
  MenuItem,
  CardContent,
  Card,
  InputLabel,
  Select,
  ThemeProvider,
  Typography
} from "@mui/material";
import Header from "../Common/Headers/header";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import Breadcrumb from "../Common/Breadcrumbs/breadcrumbs";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getLoanSchedule,
  disburseLoan,
  getCollectionsReport,
  getLoanCategoryStatus,
  getLoanCategories,
} from "../Common/Apis/api";
import PaymentIcon from "@mui/icons-material/Payment";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ReusableSnackbar from "../Common/Snackbar/snackbar";
import { Paper } from "@material-ui/core";
import "./portfolio.css";
import logout from "../Common/Logout/logout";
import { DataGrids, StatusRenderCell, blueButtonStyle, theme } from "../../constant2";
import { levelsofAccess } from "../Common/AccessLevels/accessLevel";
import CustomLoader from "../Common/CustomLoader/corestratLoader";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Portfolio = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedLoanCode, setselectedLoanCode] = useState("");
  const [loanCodeOptions, setLoanCodeOptions] = useState([]);
  const [editAccess, seteditAccess] = useState(false);
  const [showData, setShowData] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [level, setLevel] = useState("");
  const [submitted, setsubmitted] = useState(false);
  const collectionsReportResponse = useSelector(
    (state) => state.DetailsReducers.collectionsReportResponse
  );
  const collectionsReportLoading = useSelector(
    (state) => state.DetailsReducers.collectionsReportLoading
  );
  const collectionsStatusReportResponse = useSelector(
    (state) => state.DetailsReducers.collectionsStatusReportResponse
  );
  const collectionsStatusReportLoading = useSelector(
    (state) => state.DetailsReducers.collectionsStatusReportLoading
  );
  const loanCategories = useSelector(
    (state) => state.DetailsReducers.loanCategoryResponse
  );
  const loanCategoriesLoading = useSelector(
    (state) => state.DetailsReducers.loanReportLoading
  );
  const [data, setdata] = useState([]);
  const columns = [
    {
      field: "payment_date",
      headerName: "Payment Date",
      width: 150,
      editable: false,
    },
    {
      field: "collected_date",
      headerName: "Collected Date",
      width: 150,
      editable: false,
    },
    {
      field: "collected_amount",
      headerName: "Collected Amount",
      width: 150,
      editable: false,
      valueGetter: (params) =>
        params.value.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        }),
    },
    {
      field: "remaining_amount",
      headerName: "Remaining Amount",
      width: 150,
      editable: false,
      valueGetter: (params) =>
        params.value.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        }),
    },
    {
      field: "remaining_int_amt",
      headerName: "Remaining Interest Amount",
      width: 200,
      editable: false,
      valueGetter: (params) =>
        params.value.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        }),
    },
    {
      field: "remaining_princ_amt",
      headerName: "Remaining Principal Amount",
      width: 200,
      editable: false,
      valueGetter: (params) =>
        params.value.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        }),
    },
    {
      field: "collection_status",
      headerName: "Collection Status",
      width: 150,
      align: "center",
      editable: false,
      renderCell: StatusRenderCell,
    },
    {
      field: "ewi_amount",
      headerName: "EMI Amount",
      width: 100,
      editable: false,
      valueGetter: (params) =>
        params.value.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        }),
    },
  ];
  const sortModel = [{ field: 'payment_date', sort: 'desc' }];
  useEffect(() => {
    const data = sessionStorage.getItem("selected-data");
    const key = sessionStorage.getItem("key");
    if (data) {
      const parsedData = JSON.parse(data);
      setSelectedCategory(parsedData.selectedCategory);
      dispatch(
        getLoanCategoryStatus(
          parsedData.selectedCategory,
          key,
          handleLogoutButton
        )
      );
      setselectedLoanCode(parsedData.selectedLoanCode);
      dispatch(
        getCollectionsReport(
          parsedData.selectedLoanCode,
          key,
          handleLogoutButton
        )
      );
      setsubmitted(true);
      //setLoanCodeOptions(parsedData.loanCodeOptions)
    }
  }, []);

  useEffect(() => {
    dispatch(getLoanCategories(handleLogoutButton));
  }, []);
  useEffect(() => {
    const loanCategory = sessionStorage.getItem("LoanCategory");
    const key = sessionStorage.getItem("key");
    dispatch(getLoanCategoryStatus(loanCategory, key, handleLogoutButton));
  }, []);
  // const handleCategoryChange = (event) => {
  //   setLoanCodeOptions([]);
  //   setsubmitted(false);
  //   setSelectedCategory(event.target.value);
  //   setselectedLoanCode("");
  //   dispatch(getLoanCategoryStatus(event.target.value, handleLogoutButton));
  // };
  const handleLogoutButton = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    // Set a timer to navigate to the login page after at least 3 seconds
    setTimeout(() => {
      logout(navigate, dispatch); // Replace "/login" with your actual login route
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };
  const handleLoanCodeChange = (event, newValue) => {
    setselectedLoanCode(newValue);
    // console.log(selectedLoanCode, "loan");
  };

  useEffect(() => {
    const accessLevel = levelsofAccess("PortFolio");
    if (accessLevel?.toLowerCase() == "edit") {
      seteditAccess(true);
    }
    if (accessLevel?.toLowerCase() == "view") {
      seteditAccess(false);
    }
    if (accessLevel == null || accessLevel == undefined) {
      setAlertMessage("You do not have permissions to access this page. Please contact your Site Adminstrator(s) to request access.");
      setShowData(false);
    }
  }, []);

  useEffect(() => {
    if (
      collectionsReportResponse != undefined &&
      collectionsReportResponse != null
    ) {
      setdata(collectionsReportResponse.data);
    }
  }, [collectionsReportResponse]);
  useEffect(() => {
    if (
      collectionsStatusReportResponse != undefined &&
      collectionsStatusReportResponse != null
    ) {
      collectionsStatusReportResponse?.forEach((element) => {
        loanCodeOptions.push(element.display_id);
      });
    }
  }, [collectionsStatusReportResponse]);
  useEffect(() => {
    if (submitted) {
      try {
        const key = sessionStorage.getItem("key");
        dispatch(
          getCollectionsReport(selectedLoanCode, key, handleLogoutButton)
        );
      } catch (e) { }
    }
  }, [selectedLoanCode]);
  const submitData = (e) => {
    if (selectedLoanCode != "") {
      try {
        const key = sessionStorage.getItem("key");
        dispatch(
          getCollectionsReport(selectedLoanCode, key, handleLogoutButton)
        );
        setsubmitted(true);
      } catch (e) { }
    }
  };
  return (
    <div>
    <ThemeProvider theme={theme}>
      <Header />
      {loanCategoriesLoading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
          <CustomLoader/>
        </Box>
      ) : null}
      {collectionsStatusReportLoading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
          <CustomLoader/>
        </Box>
      ) : null}
      {collectionsReportLoading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
          <CustomLoader/>
        </Box>
      ) : null}
      {/* Breadcrumbs */}
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" sx={{color:"#ffffff"}}/>} className="breadcrumbsStyle" sx={{mt:1}}>
        <Breadcrumb
          icon={<AssignmentIndIcon />}
          name="Dashboard"
          path="../../Dashboard"
        />
        <Breadcrumb
          icon={<CardTravelIcon />}
          name="Portfolio"
          path="/Portfolio"
        />
        <Breadcrumb
          icon={<PaymentIcon />}
          name="Portfolio Details"
          path="/Portfolio Details"
          isCurrentPage
        />
      </Breadcrumbs>
      <Grid container spacing={2} sx={12}>
        {showData ? (<>
          <Grid container sx={{ m: 0 }} justifyContent="center" spacing={2}>
            {/* <Grid item xs={12} md={3} lg={3} sx={{ m: 2 }}>
            {loanCategories ? (
              <FormControl fullWidth>
                <InputLabel id="loan-category-label">Loan Category</InputLabel>
                <Select
                  labelId="loan-category-label"
                  id="loan-category-select"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  label="Loan Category"
                >
                  {loanCategories?.main?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.loan_category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
          </Grid> */}
            <Grid item xs={12} md={3} lg={3} sx={{ m: 2 }}>
              <FormControl fullWidth>
                <Autocomplete
                  id="loan-code-select"
                  options={loanCodeOptions}
                  getOptionLabel={(option) => option}
                  value={selectedLoanCode}
                  //disabled={selectedCategory === ""}
                  onChange={handleLoanCodeChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Loan Code" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2} lg={2} sx={{ m: 2 }}>
              <Button
                type="submit"
                // variant="contained"
                // color="primary"
                sx={blueButtonStyle}
                onClick={(event) => submitData(event)}
                style={{
                  textTransform: "none",
                  backgroundColor: "var(--primary-color)",
                  height: "100%",
                  width: "100%",
                }}
              >
                Submit
              </Button>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{ mr: 5, ml: 5 }}
              justifyContent="center"
              alignItems="center"
            >
              {collectionsReportResponse &&
                collectionsReportResponse?.data &&
                submitted ? (
                <>
                  <Grid item xs={12} md={7} lg={7} sx={{ mt: 1 }}>
                    <div className="customer-details-box">
                      <Typography variant="h6" gutterBottom>
                        <b>Customer Details</b>
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography variant="body2">
                            <b>Name:</b>{" "}
                            {collectionsReportResponse?.customer_name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography variant="body2">
                            <b>Bank Name:</b>{" "}
                            {collectionsReportResponse?.customer_bank_name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography variant="body2">
                            <b>Bank Account:</b>{" "}
                            {collectionsReportResponse?.customer_bank_account}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography variant="body2">
                            <b>Mobile Number:</b>{" "}
                            {collectionsReportResponse?.mobile_number}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography variant="body2">
                            <b>Address:</b>{" "}
                            {collectionsReportResponse?.permanent_addr}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={5} lg={5}>
                    <div className="loan-details-box">
                      <Typography variant="h6" gutterBottom>
                        <b>Loan Details</b>
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography variant="body2">
                            <b>Outstanding Principal Amount:</b>{" "}
                            {collectionsReportResponse?.principal_os?.toLocaleString(
                              "en-IN",
                              {
                                style: "currency",
                                currency: "INR",
                              }
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography variant="body2">
                            <b>Outstanding Interest Amount:</b>{" "}
                            {collectionsReportResponse?.interest_os?.toLocaleString(
                              "en-IN",
                              {
                                style: "currency",
                                currency: "INR",
                              }
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                          <Typography variant="body2">
                            <b>Outstanding Total Amount:</b>{" "}
                            {collectionsReportResponse?.total_os?.toLocaleString(
                              "en-IN",
                              {
                                style: "currency",
                                currency: "INR",
                              }
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            xs={12}
            lg={12}
          >
            <Grid item xs={12} sx={{ mt: 1, mb: 2, ml: 4 }}>
              {data?.length > 0 && submitted ? (
                <Box sx={{ height: "50vh", width: "93vw", m: 4 }}>
                  <DataGrids rows={data} columns={columns} page_size={5} initialSortModel={sortModel}/>
                </Box>
              ) : (
                <>
                  {submitted &&
                    selectedLoanCode != null &&
                    !collectionsReportLoading ? (
                    <Grid sx={{ m: 4 }}>
                      <Alert severity="error">
                        No Data available for selected Loan Code.
                      </Alert>
                    </Grid>
                  ) : null}
                </>
              )}
            </Grid>
          </Grid>
        </>):(<>
          <Grid item xs={12} md={12}>
            <Alert severity="error" sx={{ m: 5 }}>{alertMessage}</Alert>
            </Grid>
        </>)}
      </Grid>
      <ReusableSnackbar
        open={showSnackbar}
        onClose={closeSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      </ThemeProvider>
    </div>
  );
};
export default Portfolio;
