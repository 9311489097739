import { ActionTypes } from "../Types/ActionTypes";
const initialState = {
  loanReportLoading: null,
  loanReportSuccess: null,
  loanReportResponse: [],
  loanReportFail: null,

  loanDetailsLoading: null,
  loanDetailsSuccess: null,
  loanDetailsResponse: [],
  loanDetailsFail: null,

  loanScheduleLoading: null,
  loanScheduleSuccess: null,
  loanScheduleResponse: [],
  loanScheduleFail: null,

  loanDisburseLoading: null,
  loanDisburseSuccess: null,
  loanDisburseResponse: [],
  loanDisburseFail: null,

  collectionsReportLoading: null,
  collectionsReportSuccess: null,
  collectionsReportResponse: [],
  collectionsReportFail: null,

  loanCategoryLoading: null,
  loanCategorySuccess: null,
  loanCategoryResponse: [],
  loanCategoryFail: null,

  portfolioDataLoading: null,
  portfolioDataSuccess: null,
  portfolioDataResponse: [],
  portfolioDataFail: null,

  customerNameLoading: null,
  customerNameSuccess: null,
  customerNameResponse: [],
  customerNameFail: null,

  collectionsStatusReportLoading: null,
  collectionsStatusReportSuccess: null,
  collectionsStatusReportResponse: [],
  collectionsStatusReportFail: null,

  partnersListLoading: null,
  partnersListSuccess: null,
  partnersListResponse: [],
  partnersListFail: null,

  loanDisbursementLoading: null,
  loanDisbursementSuccess: null,
  loanDisbursementResponse: [],
  loanDisbursementFail: null,

  bankDetailsLoading: null,
  bankDetailsSuccess: null,
  bankDetailsResponse: [],
  bankDetailsFail: null,

  reportTypeLoading: null,
  reportTypeSuccess: null,
  reportTypeResponse: [],
  reportTypeFail: null,

  TokenDecodeResponse: [],

  clearPersistedState: [],

  dealerLoanDetailLoading: null,
  dealerLoanDetailSuccess: null,
  dealerLoanDetailResponse: [],
  dealerLoanDetailFail: null,

  runningTrancheLoading: null,
  runningTrancheSuccess: null,
  runningTrancheResponse: [],
  runningTrancheFail: null,

  pendingDisbursementLoading: null,
  pendingDisbursementSuccess: null,
  pendingDisbursementResponse: [],
  pendingDisbursementFail: null,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.LOAN_REPORT_LOADING:
      return {
        ...state,
        loanReportLoading: true,
        loanReportSuccess: false,
        loanReportResponse: [],
        loanReportFail: false,
      };
    case ActionTypes.LOAN_REPORT_SUCCESS:
      return {
        ...state,
        loanReportLoading: false,
        loanReportSuccess: true,
        loanReportResponse: action.payload,
        loanReportFail: false,
      };
    case ActionTypes.LOAN_REPORT_FAIL:
      return {
        ...state,
        loanReportLoading: false,
        loanReportSuccess: false,
        loanReportResponse: [],
        loanReportFail: true,
      };
    case ActionTypes.LOAN_REPORT_CLEAR_DATA:
      return {
        ...state,
        loanReportLoading: null,
        loanReportSuccess: null,
        loanReportResponse: [],
        loanReportFail: null,
      };
    case ActionTypes.LOAN_DETAILS_LOADING:
      return {
        ...state,
        loanDetailsLoading: true,
        loanDetailsSuccess: false,
        loanDetailsResponse: [],
        loanDetailsFail: false,
      };
    case ActionTypes.LOAN_DETAILS_SUCCESS:
      return {
        ...state,
        loanDetailsLoading: false,
        loanDetailsSuccess: true,
        loanDetailsResponse: action.payload,
        loanDetailsFail: false,
      };
    case ActionTypes.LOAN_DETAILS_FAIL:
      return {
        ...state,
        loanDetailsLoading: false,
        loanDetailsSuccess: false,
        loanDetailsResponse: [],
        loanDetailsFail: true,
      };
    case ActionTypes.LOAN_DETAILS_CLEAR_DATA:
      return {
        ...state,
        loanDetailsLoading: null,
        loanDetailsSuccess: null,
        loanDetailsResponse: [],
        loanDetailsFail: null,
      };
    case ActionTypes.LOAN_SCHEDULE_LOADING:
      return {
        ...state,
        loanScheduleLoading: true,
        loanScheduleSuccess: false,
        loanScheduleResponse: [],
        loanScheduleFail: false,
      };
    case ActionTypes.LOAN_SCHEDULE_SUCCESS:
      return {
        ...state,
        loanScheduleLoading: false,
        loanScheduleSuccess: true,
        loanScheduleResponse: action.payload,
        loanScheduleFail: false,
      };
    case ActionTypes.LOAN_SCHEDULE_FAIL:
      return {
        ...state,
        loanScheduleLoading: false,
        loanScheduleSuccess: false,
        loanScheduleResponse: [],
        loanScheduleFail: true,
      };
    case ActionTypes.LOAN_SCHEDULE_CLEAR_DATA:
      return {
        ...state,
        loanScheduleLoading: null,
        loanScheduleSuccess: null,
        loanScheduleResponse: [],
        loanScheduleFail: null,
      };
    case ActionTypes.LOAN_DISBURSE_LOADING:
      return {
        ...state,
        loanDisburseLoading: true,
        loanDisburseSuccess: false,
        loanDisburseResponse: [],
        loanDisburseFail: false,
      };
    case ActionTypes.LOAN_DISBURSE_SUCCESS:
      return {
        ...state,
        loanDisburseLoading: false,
        loanDisburseSuccess: true,
        loanDisburseResponse: action.payload,
        loanDisburseFail: false,
      };
    case ActionTypes.LOAN_DISBURSE_FAIL:
      return {
        ...state,
        loanDisburseLoading: false,
        loanDisburseSuccess: false,
        loanDisburseResponse: [],
        loanDisburseFail: true,
      };
    case ActionTypes.LOAN_DISBURSE_CLEAR_DATA:
      return {
        ...state,
        loanDisburseLoading: null,
        loanDisburseSuccess: null,
        loanDisburseResponse: [],
        loanDisburseFail: null,
      };
    case ActionTypes.COLLECTIONS_REPORT_LOADING:
      return {
        ...state,
        collectionsReportLoading: true,
        collectionsReportSuccess: false,
        collectionsReportResponse: [],
        collectionsReportFail: false,
      };
    case ActionTypes.COLLECTIONS_REPORT_SUCCESS:
      return {
        ...state,
        collectionsReportLoading: false,
        collectionsReportSuccess: true,
        collectionsReportResponse: action.payload,
        collectionsReportFail: false,
      };
    case ActionTypes.COLLECTIONS_REPORT_FAIL:
      return {
        ...state,
        collectionsReportLoading: false,
        collectionsReportSuccess: false,
        collectionsReportResponse: [],
        collectionsReportFail: true,
      };
    case ActionTypes.COLLECTIONS_REPORT_CLEAR_DATA:
      return {
        ...state,
        collectionsReportLoading: null,
        collectionsReportSuccess: null,
        collectionsReportResponse: [],
        collectionsReportFail: null,
      };
    case ActionTypes.LOAN_CATEGORY_LOADING:
      return {
        ...state,
        loanCategoryLoading: true,
        loanCategorySuccess: false,
        loanCategoryResponse: [],
        loanCategoryFail: false,
      };
    case ActionTypes.LOAN_CATEGORY_SUCCESS:
      return {
        ...state,
        loanCategoryLoading: false,
        loanCategorySuccess: true,
        loanCategoryResponse: action.payload,
        loanCategoryFail: false,
      };
    case ActionTypes.LOAN_CATEGORY_FAIL:
      return {
        ...state,
        loanCategoryLoading: false,
        loanCategorySuccess: false,
        loanCategoryResponse: [],
        loanCategoryFail: true,
      };
    case ActionTypes.LOAN_CATEGORY_CLEAR_DATA:
      return {
        ...state,
        loanCategoryLoading: null,
        loanCategorySuccess: null,
        loanCategoryResponse: [],
        loanCategoryFail: null,
      };

    case ActionTypes.PORTFOLIO_DATA_LOADING:
      return {
        ...state,
        portfolioDataLoading: true,
        portfolioDataSuccess: false,
        portfolioDataResponse: [],
        portfolioDataFail: false,
      };
    case ActionTypes.PORTFOLIO_DATA_SUCCESS:
      return {
        ...state,
        portfolioDataLoading: false,
        portfolioDataSuccess: true,
        portfolioDataResponse: action.payload,
        portfolioDataFail: false,
      };
    case ActionTypes.PORTFOLIO_DATA_FAIL:
      return {
        ...state,
        portfolioDataLoading: false,
        portfolioDataSuccess: false,
        portfolioDataResponse: [],
        portfolioDataFail: true,
      };
    case ActionTypes.PORTFOLIO_DATA_CLEAR_DATA:
      return {
        ...state,
        portfolioDataLoading: null,
        portfolioDataSuccess: null,
        portfolioDataResponse: [],
        portfolioDataFail: null,
      };

    case ActionTypes.CUSTOMER_NAME_LOADING:
      return {
        ...state,
        customerNameLoading: true,
        customerNameSuccess: false,
        customerNameResponse: [],
        customerNameFail: false,
      };
    case ActionTypes.CUSTOMER_NAME_SUCCESS:
      return {
        ...state,
        customerNameLoading: false,
        customerNameSuccess: true,
        customerNameResponse: action.payload,
        customerNameFail: false,
      };
    case ActionTypes.CUSTOMER_NAME_FAIL:
      return {
        ...state,
        customerNameLoading: false,
        customerNameSuccess: false,
        customerNameResponse: [],
        customerNameFail: true,
      };
    case ActionTypes.CUSTOMER_NAME_CLEAR_DATA:
      return {
        ...state,
        customerNameLoading: null,
        customerNameSuccess: null,
        customerNameResponse: [],
        customerNameFail: null,
      };

    case ActionTypes.COLLECTIONS_STATUS_REPORT_LOADING:
      return {
        ...state,
        collectionsStatusReportLoading: true,
        collectionsStatusReportSuccess: false,
        collectionsStatusReportResponse: [],
        collectionsStatusReportFail: false,
      };
    case ActionTypes.COLLECTIONS_STATUS_REPORT_SUCCESS:
      return {
        ...state,
        collectionsStatusReportLoading: false,
        collectionsStatusReportSuccess: true,
        collectionsStatusReportResponse: action.payload,
        collectionsStatusReportFail: false,
      };
    case ActionTypes.COLLECTIONS_STATUS_REPORT_FAIL:
      return {
        ...state,
        collectionsStatusReportLoading: false,
        collectionsStatusReportSuccess: false,
        collectionsStatusReportResponse: [],
        collectionsStatusReportFail: true,
      };
    case ActionTypes.COLLECTIONS_STATUS_REPORT_CLEAR_DATA:
      return {
        ...state,
        collectionsStatusReportLoading: null,
        collectionsStatusReportSuccess: null,
        collectionsStatusReportResponse: [],
        collectionsStatusReportFail: null,
      };
    case ActionTypes.PARTNERS_LIST_SUCCESS:
      return {
        ...state,
        partnersListLoading: false,
        partnersListSuccess: true,
        partnersListResponse: action.payload,
        partnersListFail: false,
      };
    case ActionTypes.PARTNERS_LIST_FAIL:
      return {
        ...state,
        partnersListLoading: false,
        partnersListSuccess: false,
        partnersListResponse: [],
        partnersListFail: true,
      };
    case ActionTypes.LOAN_DISBURSEMENT_LOADING:
      return {
        ...state,
        loanDisbursementLoading: true,
        loanDisbursementSuccess: false,
        loanDisbursementResponse: [],
        loanDisbursementFail: false,
      };
    case ActionTypes.LOAN_DISBURSEMENT_SUCCESS:
      return {
        ...state,
        loanDisbursementLoading: false,
        loanDisbursementSuccess: true,
        loanDisbursementResponse: action.payload,
        loanDisbursementFail: false,
      };
    case ActionTypes.LOAN_DISBURSEMENT_FAIL:
      return {
        ...state,
        loanDisbursementLoading: false,
        loanDisbursementSuccess: false,
        loanDisbursementResponse: [],
        loanDisbursementFail: true,
      };
    case ActionTypes.LOAN_DISBURSEMENT_CLEAR_DATA:
      return {
        ...state,
        loanDisbursementLoading: null,
        loanDisbursementSuccess: null,
        loanDisbursementResponse: [],
        loanDisbursementFail: null,
      };
    case ActionTypes.BANK_DETAILS_LOADING:
      return {
        ...state,
        bankDetailsLoading: true,
        bankDetailsSuccess: false,
        bankDetailsResponse: [],
        bankDetailsFail: false,
      };
    case ActionTypes.BANK_DETAILS_SUCCESS:
      return {
        ...state,
        bankDetailsLoading: false,
        bankDetailsSuccess: true,
        bankDetailsResponse: action.payload,
        bankDetailsFail: false,
      };
    case ActionTypes.BANK_DETAILS_FAIL:
      return {
        ...state,
        bankDetailsLoading: false,
        bankDetailsSuccess: false,
        bankDetailsResponse: [],
        bankDetailsFail: true,
      };
    case ActionTypes.BANK_DETAILS_CLEAR_DATA:
      return {
        ...state,
        bankDetailsLoading: null,
        bankDetailsSuccess: null,
        bankDetailsResponse: [],
        bankDetailsFail: null,
      };

    case ActionTypes.CLEAR_PERSISTED_STATE:
      return {
        clearPersistedState: initialState,
      };
    case ActionTypes.REPORT_TYPE_LOADING:
      return {
        ...state,
        reportTypeLoading: true,
        reportTypeSuccess: false,
        reportTypeResponse: [],
        reportTypeFail: false,
      };
    case ActionTypes.REPORT_TYPE_SUCCESS:
      return {
        ...state,
        reportTypeLoading: false,
        reportTypeSuccess: true,
        reportTypeResponse: action.payload,
        reportTypeFail: false,
      };
    case ActionTypes.REPORT_TYPE_FAIL:
      return {
        ...state,
        reportTypeLoading: false,
        reportTypeSuccess: false,
        reportTypeResponse: [],
        reportTypeFail: true,
      };
    case ActionTypes.REPORT_TYPE_CLEAR_DATA:
      return {
        ...state,
        reportTypeLoading: null,
        reportTypeSuccess: null,
        reportTypeResponse: [],
        reportTypeFail: null,
      };
    case ActionTypes.DEALER_LOAN_DETAILS_LOADING:
      return {
        ...state,
        dealerLoanDetailLoading: true,
        dealerLoanDetailSucess: false,
        dealerLoanDetailResponse: [],
        dealerLoanDetailFail: false,
      };
    case ActionTypes.DEALER_LOAN_DETAILS_SUCCESS:
      return {
        ...state,
        dealerLoanDetailLoading: false,
        dealerLoanDetailSuccess: true,
        dealerLoanDetailResponse: action.payload,
        dealerLoanDetailFail: false,
      };
    case ActionTypes.DEALER_LOAN_DETAILS_FAIL:
      return {
        ...state,
        dealerLoanDetailLoading: false,
        dealerLoanDetailSuccess: false,
        dealerLoanDetailResponse: [],
        dealerLoanDetailFail: true,
      };
    case ActionTypes.DEALER_LOAN_DETAILS_CLEAR_DATA:
      return {
        ...state,
        dealerLoanDetailLoading: null,
        dealerLoanDetailSuccess: null,
        dealerLoanDetailResponse: [],
        dealerLoanDetailFail: null,
      };
    case ActionTypes.TRANCHE_DETAILS_LOADING:
      return {
        ...state,
        runningTrancheLoading: true,
        runningTrancheSuccess: false,
        runningTrancheResponse: [],
        runningTrancheFail: false,
      };
    case ActionTypes.TRANCHE_DETAILS_SUCCESS:
      return {
        ...state,
        runningTrancheLoading: false,
        runningTrancheSuccess: true,
        runningTrancheResponse: action.payload,
        runningTrancheFail: false,
      };
    case ActionTypes.TRANCHE_DETAILS_FAIL:
      return {
        ...state,
        runningTrancheLoading: false,
        runningTrancheSuccess: false,
        runningTrancheResponse: [],
        runningTrancheFail: true,
      };
    case ActionTypes.TRANCHE_DETAILS_CLEAR_DATA:
      return {
        ...state,
        runningTrancheLoading: null,
        runningTrancheSuccess: null,
        runningTrancheResponse: [],
        runningTrancheFail: null,
      };
    case ActionTypes.TOKEN_DECODE:
      return {
        TokenDecodeResponse: [],
      };
    case ActionTypes.PENDING_DISBURSEMENT_LOADING:
      return {
        ...state,
        pendingDisbursementLoading: true,
        pendingDisbursementSuccess: false,
        pendingDisbursementResponse: [],
        pendingDisbursementFail: false,
      };
    case ActionTypes.PENDING_DISBURSEMENT_SUCCESS:
      return {
        ...state,
        pendingDisbursementLoading: false,
        pendingDisbursementSuccess: true,
        pendingDisbursementResponse: action.payload,
        pendingDisbursementFail: false,
      };
    case ActionTypes.PENDING_DISBURSEMENT_FAIL:
      return {
        ...state,
        pendingDisbursementLoading: false,
        pendingDisbursementSuccess: false,
        pendingDisbursementResponse: [],
        pendingDisbursementFail: true,
      };
    case ActionTypes.PENDING_DISBURSEMENT_CLEAR_DATA:
      return {
        ...state,
        pendingDisbursementLoading: null,
        pendingDisbursementSuccess: null,
        pendingDisbursementResponse: [],
        pendingDisbursementFail: null,
      };
    default:
      return {
        state,
      };
  }
}
