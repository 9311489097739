import React, { useState, useEffect } from "react";
import {
  Grid,
  Breadcrumbs,
  Button,
  LinearProgress,
  Box,
  Tooltip,
  Alert,
  ThemeProvider,
} from "@mui/material";
import Header from "../Common/Headers/header";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import Breadcrumb from "../Common/Breadcrumbs/breadcrumbs";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { pendingDisbursements } from "../Common/Apis/api";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import "./approvals.css";
import ReusableSnackbar from "../Common/Snackbar/snackbar";
import InfoIcon from "@mui/icons-material/Info";
import { Typography } from "@mui/material";
import logout from "../Common/Logout/logout";
import { levelsofAccess } from "../Common/AccessLevels/accessLevel";
import {
  DataGrids,
  DateRenderCell,
  StatusRenderCell,
  blueButtonStyle,
  theme,
} from "../../constant2";
import CustomLoader from "../Common/CustomLoader/corestratLoader";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const PendingDisbursement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [editAccess, seteditAccess] = useState(false);
  const [showData, setShowData] = useState(true);
  const [alertMessage, setAlertMessage] = useState("");
  const [level, setLevel] = useState("");
  const pendingDisbursementResponse = useSelector(
    (state) => state.DetailsReducers.pendingDisbursementResponse
  );
  const pendingDisbursementLoading = useSelector(
    (state) => state.DetailsReducers.pendingDisbursementLoading
  );
  const pendingDisbursementFail = useSelector(
    (state) => state.DetailsReducers.pendingDisbursementFail
  );
  const [data, setdata] = useState([]);
  const columns = [
    { field: "id", headerName: "ID", width: 110 },
    {
      field: "partner_loan_code",
      headerName: "Partner Loan Code",
      width: 140,
      editable: false,
    },
    {
      field: "total_amount",
      headerName: "Total Amount",
      width: 110,
      editable: false,
      valueGetter: (params) =>
        params.value.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        }),
    },
    {
      field: "colender_share",
      headerName: "Colender Share",
      width: 200,
      editable: false,
      valueGetter: (params) =>
        params.value.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        }),
    },
    {
      field: "originator_share",
      headerName: "Originator Share",
      width: 200,
      editable: false,
      valueGetter: (params) =>
        params.value.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        }),
    },
    {
      field: "suggested_mode_of_transfer",
      headerName: "Suggested Mode of Transfer",
      width: 120,
      editable: false,
    },
  ];

  useEffect(() => {
    const loanCategory = sessionStorage.getItem("LoanCategory");
    const key = sessionStorage.getItem("key");
    try {
      dispatch(pendingDisbursements(key, handleLogoutButton));
    } catch (e) {
      setdata([]);
    }
  }, []);
  useEffect(() => {
    if (pendingDisbursementFail == true) {
      setdata([]);
    }
  }, [pendingDisbursementFail]);
  useEffect(() => {
    const accessLevel = levelsofAccess("Disbursement");
    if (accessLevel?.toLowerCase() == "edit") {
      seteditAccess(true);
    }
    if (accessLevel?.toLowerCase() == "view") {
      seteditAccess(false);
    }
    if (accessLevel == null || accessLevel == undefined) {
      setAlertMessage(
        "You do not have permissions to access this page. Please contact your Site Adminstrator(s) to request access."
      );
      setShowData(false);
    }
  }, []);

  const handleLogoutButton = () => {
    setSnackbarMessage("Session has expired. Please login again.");
    setSnackbarSeverity("error");
    setShowSnackbar(true);
    // Set a timer to navigate to the login page after at least 3 seconds
    setTimeout(() => {
      logout(navigate, dispatch); // Replace "/login" with your actual login route
    }, 3000);
  };
  const closeSnackbar = () => {
    setShowSnackbar(false);
  };
  useEffect(() => {
    if (
      pendingDisbursementResponse != undefined &&
      pendingDisbursementResponse != null
    ) {
      if (pendingDisbursementResponse?.data) {
        setdata(pendingDisbursementResponse?.data);
      }
    }
  }, [pendingDisbursementResponse]);
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Header />
        {pendingDisbursementLoading ? (
          <Box sx={{ width: "100%" }}>
            <CustomLoader />
          </Box>
        ) : null}
        <Breadcrumbs
          separator={
            <NavigateNextIcon fontSize="small" sx={{ color: "#ffffff" }} />
          }
          className="breadcrumbsStyle"
          sx={{ mt: 1 }}
        >
          <Breadcrumb
            icon={<AssignmentIndIcon />}
            name="Dashboard"
            path="../../Dashboard"
          />
          <Breadcrumb
            icon={<CardTravelIcon />}
            name="Approvals and Disbursements"
            path="/Approvals"
          />
          <Breadcrumb
            icon={<FileCopyIcon />}
            name="Pending Disbursements"
            path="/PendingDisbursement"
            isCurrentPage
          />
        </Breadcrumbs>
        <Grid container spacing={2}>
          {showData ? (
            <>
              {data.length > 0 ? (
                <Grid item xs={12} sx={{ height: "77vh", width: "95vw", m: 4 }}>
                  {/* Place the DataGrid within its own Grid item */}
                  <DataGrids rows={data} columns={columns} page_size={10} />
                </Grid>
              ) : (
                <Grid item xs={12} sx={{ m: 4 }}>
                  <Alert severity="warning">
                    No data available for this Category
                  </Alert>
                </Grid>
              )}
            </>
          ) : (
            <>
              <Grid item xs={12} md={12}>
                <Alert severity="error" sx={{ m: 5 }}>
                  {alertMessage}
                </Alert>
              </Grid>
            </>
          )}
        </Grid>
        <ReusableSnackbar
          open={showSnackbar}
          onClose={closeSnackbar}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
      </ThemeProvider>
    </div>
  );
};

export default PendingDisbursement;
