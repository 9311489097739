import React from "react";
import "./Banner.css";
import dark_arrow from "../../../Assets/dark-arrow.png";
import { useNavigate } from "react-router-dom";
const Hero = () => {
  const navigate = useNavigate();
  const navigateToLogin = () => {
    // navigate("./login");
    navigate("./login");
    sessionStorage.clear();
  };
  return (
    <div className="hero">
      <div className="hero-content">
        <div className="hero-text">
        <h1>Empowering Collaboration, Revolutionizing Lending.</h1>
        <p>
          Lendsphere is a customisable platform tailored to meet the specific
          needs of clients. Its flexible APIs enable seamless integration with
          existing systems, establishing a shared account structure for
          collaborative fund management. This facilitates unified disbursement
          and repayment processes.
        </p>
        <a
          onClick={navigateToLogin}
          className="btn"
          target="_blank"
          rel="noopener noreferrer"
        >
          <b>Login/Signup <img src={dark_arrow} alt="" /></b>
        </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;
